import { Popover } from '@flyer/components';
import { BgImage } from '@flyer/components/next';
import { m } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRemoteConfig } from '@/providers/remote-config';
import useShowNetworkSpeedLow from '@/hooks/useShowNetworkSpeedLow';
import iconClock from '@/assets/icons/image-clock.png';
import BgHome from '@/assets/images/bg-home.jpg';
import menuProfileBackground from '@/assets/images/bg-menu-profile.png';
import ChangeProfileIcon from '@/assets/svg/ic-change-profile.svg';
import SettingProfileIcon from '@/assets/svg/ic-profile-setting.svg';
import { Avatar, Button } from '@/components/common';
import BgProfileNav from '@/components/exam/ExamHomeView/assets/profile-nav-bg.png';
import ExamBody from '@/components/exam/ExamHomeView/ExamBody';
import ExamFooter from '@/components/exam/ExamHomeView/ExamFooter/ExamFooter';
import HeaderDesktop from '@/components/exam/ExamHomeView/ExamHeader/HeaderDesktop';
import { useAuth } from '@/providers/auth';
import ROUTES from '@/lib/routes';
import getMediaUrl from '@/lib/utils';
import 'swiper/css';
import 'swiper/css/navigation';
import profileBackgroundMB from './assets/Badges-mobile.png';
import NextIcon from './assets/next.png';
import PreIcon from './assets/pre.png';
import JoinChallengePopover from './JoinChallengePopover';
import iconClose from './assets/icon-close.png';
import ExamBodyStatic from './ExamBodyStatic';
import { graphql } from '@/__generated__';
import { TypeExamTestLevel } from '@/__generated__/graphql';

const WritingTestLevel = graphql(`
  query WritingTestLevel($filterBy: TestLevelFilter) {
    testLevels(first: 200, filterBy: $filterBy) {
      nodes {
        id: testLevelId
        name
        slug
        __typename
      }
      __typename
    }
  }
`);

const DynamicHeaderMobile = dynamic(
  () => import('@/components/exam/ExamHomeView/ExamHeader/HeaderMobile'),
  {
    ssr: false,
  },
);

export default function HomePage() {
  const { t } = useTranslation('common');
  const { currentLearnerProfile } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const remoteConfig = useRemoteConfig();

  const [isShowNotiNetWork, setIsShowNotiNetWork] = useState<boolean>(useShowNetworkSpeedLow());

  const { data: dataWriting } = useQuery(WritingTestLevel, {
    context: {
      v2: true,
    },
    variables: {
      filterBy: {
        isWriting: true,
        typeTestLevel: [TypeExamTestLevel.Classic],
      },
    },
  });

  return (
    <BgImage
      alt="Profile"
      src={BgHome}
      data-test="home-page"
      className="main-home flex flex-col justify-between select-none min-h-screen mobile-home overflow-hidden"
      wrapClassName="h-screen overflow-hidden"
      imageClassName="h-full object-cover w-full"
    >
      <div className="home-header xl:pt-3 flex-row justify-center m-auto hidden-custom sm:flex max-w-[1420px]">
        <HeaderDesktop dataWriting={dataWriting} />
      </div>
      <div className="home-header flex justify-start pt-[8px] sm:hidden">
        {isOpen && <div aria-hidden className="bg-overlay" onClick={() => setIsOpen(false)} />}
        <div className="item-1 pl-[16px]">
          <JoinChallengePopover />
        </div>
        <div className="item-4 fixed left-[50%] top-[40px] translate-x-[-50%] translate-y-[-50%]">
          {currentLearnerProfile ? (
            <div className="flex flex-col">
              <BgImage alt="Profile" src={profileBackgroundMB} wrapClassName="w-fit max-w-[110px]">
                <BgImage
                  src={menuProfileBackground}
                  alt="Menu profile"
                  wrapClassName="bottom-[10px] !absolute w-full"
                  imageClassName="sm:w-full"
                >
                  <Link
                    className="w-[10%] absolute left-2 top-[50%] inline-block translate-y-[-50%] [&>svg>*]:hover:fill-[#10E7FD] "
                    href={ROUTES.PROFILE}
                  >
                    <ChangeProfileIcon className="w-full [&>*]:transition-colors [&>*]:duration-300" />
                  </Link>
                  <Link
                    href={ROUTES.EDIT_PROFILE}
                    className="w-[8%] absolute right-2 sm:right-6 top-[50%] inline-block translate-y-[-50%] [&>svg>*]:hover:fill-[#10E7FD]"
                  >
                    <SettingProfileIcon className="w-full [&>*]:transition-colors [&>*]:duration-300" />
                  </Link>
                  <div className="absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[10%]">
                    <Link href={ROUTES.EDIT_PROFILE}>
                      <Avatar
                        src={
                          currentLearnerProfile?.avatarUrl
                            ? getMediaUrl(currentLearnerProfile?.avatarUrl, 'avatar')
                            : undefined
                        }
                      />
                    </Link>
                  </div>
                  <div className="absolute left-[50%] translate-x-[-50%] w-full text-center bottom-[-90%] text-slate-50 z-10">
                    <Popover
                      content={currentLearnerProfile?.displayName}
                      onlyShowOnEllipsis
                      contentClassName="text-[8px] sm:text-[10px] xl:text-[14px] w-[200px] sm:w-full"
                    >
                      <div className="text-[12px]">{currentLearnerProfile?.displayName}</div>
                    </Popover>
                  </div>
                </BgImage>
              </BgImage>
            </div>
          ) : (
            <div className="w-[144px]">
              <BgImage alt="Profile" src={BgProfileNav}>
                <div className="flex flex-col gap-[3px] xl:flex-col userNot-login h-full justify-center items-center p-6 pb-[32px]">
                  <Link href={ROUTES.SIGN_IN()}>
                    <Button
                      className="w-[114px] h-[27px]"
                      classNameText="flex items-center justify-center text-[11px]  p-3 uppercase"
                    >
                      {t('exam.sign_in')}
                    </Button>
                  </Link>
                  <div className="h-[7px]" />
                  <Link href={ROUTES.SIGN_UP()}>
                    <Button
                      colorScheme="red"
                      className="w-[114px] h-[24px]"
                      classNameText="flex items-center justify-center text-[11px] p-3 uppercase"
                    >
                      {t('exam.sign_up')}
                    </Button>
                  </Link>
                </div>
              </BgImage>
            </div>
          )}
        </div>
        <DynamicHeaderMobile setIsOpen={setIsOpen} isOpen={isOpen} dataWriting={dataWriting} />
      </div>
      <div className="home-body m-auto w-full sm:w-[calc(100%-10vw)] flex flex-row lg:mb-20 h-3/5 basis-full items-center relative">
        {isShowNotiNetWork && remoteConfig.enabledNotiNetworkSlow && (
          <div className="h-fit z-10 bg-[#FEEED7] rounded-[8px] absolute w-[300px] md:w-[390px] top-[2%] p-2.5 mx-8 right-0">
            <div className="flex relative flex-row items-center gap-2">
              <div
                aria-hidden
                onClick={() => setIsShowNotiNetWork(false)}
                className="absolute left-[-20px] top-[-20px] cursor-pointer"
              >
                <Image alt="iconClose" src={iconClose} className="w-[24px]" />
              </div>
              <Image alt="flyer" src={iconClock} className="w-[35px]" />
              <span className="text-[14px] font-semibold text-[#31203B]">
                {t('network_speed_low')}
              </span>
            </div>
          </div>
        )}
        {remoteConfig.disabledAnimationHome ===
        undefined ? null : remoteConfig.disabledAnimationHome ? (
          <ExamBodyStatic />
        ) : (
          <ExamBody />
        )}
        <div className="z-[101] md:z-[1] button-navigation absolute bottom-[10%] w-full sm:bottom-auto h-[0]">
          <m.div
            className="swiper-button-next xl:!w-[35px] 2xl:!w-[56px]"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Image src={NextIcon} alt="flyer next" />
          </m.div>
          <div className="sm:hidden swiper-pagination flex justify-center [*>.swiper-pagination-bullet:w-[15px]] text-[7px]" />
          <m.div
            className="swiper-button-prev xl:!w-[35px] 2xl:!w-[56px]"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Image src={PreIcon} alt="flyer prev" />
          </m.div>
        </div>
      </div>
      <ExamFooter />
    </BgImage>
  );
}
