import useTranslation from 'next-translate/useTranslation';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import tagNewEn from '../assets/tag_new_en.png';
import tagNewVi from '../assets/tag_new_vi.png';

function ItemDiamond({
  title,
  numberValue,
  icon,
  linkAction,
  iconClassName,
  showNew = false,
  boxClassName,
  valueClassName,
  textClassName,
  isCanActionLink,
}: {
  title: string;
  numberValue: number | string;
  icon: StaticImageData;
  linkAction: string;
  iconClassName: string;
  showNew: boolean;
  boxClassName?: string;
  textClassName?: string;
  valueClassName?: string;
  isCanActionLink?: boolean;
}) {
  const { lang } = useTranslation();
  // const isShowTagNew = useShowSaleOff();
  const isShowTagNew = false;
  return (
    <Link
      href={isCanActionLink ? linkAction : '/'}
      className={twMerge(isCanActionLink ? 'cursor-pointer' : 'cursor-auto')}
    >
      <div
        className={twMerge(
          'w-[95px] max-sm:min-w-[95px] h-[40px] sm:w-[8vw] sm:h-[4vw] sm:rounded-[9px] lg:w-[90px] lg:h-[45px] xl:w-[115px] 2xl:w-[132px] xl:h-[56px]  flex flex-row justify-center lg:justify-start lg:pl-[10px] items-center gap-[6px] bg-[#583ED9] border-solid border-[3px] border-[#7B61FF] rounded-[8px] lg:rounded-[16px] z-[10] relative',
          boxClassName,
        )}
      >
        <Image src={icon} alt="flyer" className={iconClassName} />
        <div className="flex flex-col">
          <div
            className={twMerge(
              'text-white font-bold text-[7px] sm:text-[0.8vw] lg:text-[7px] xl:text-[9px] uppercase',
              textClassName,
            )}
          >
            {title}
          </div>
          <div
            className={twMerge(
              'text-white text-[14px] sm:text-[0.8vw] xl:text-[18px] font-bold',
              valueClassName,
            )}
          >
            {numberValue}
          </div>
        </div>
        {isShowTagNew && showNew && (
          <Image
            src={lang === 'en' ? tagNewEn : tagNewVi}
            alt="flyer"
            className="absolute bottom-[-0.8rem] w-[45px] h-[45px] right-[-0.8rem] sm:bottom-[-0.7rem] sm:w-[35px] sm:h-[35px] sm:right-[-0.7rem] lg:bottom-[-0.8rem] lg:w-[45px] lg:h-[45px] lg:right-[-0.8rem] xl:bottom-[-1.2rem] xl:w-[63px] xl:h-[62px] xl:right-[-0.9rem]"
          />
        )}
      </div>
    </Link>
  );
}
export default ItemDiamond;
