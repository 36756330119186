
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useQuery } from '@apollo/client';
import { useModal } from '@flyer/components';
import { isClient } from '@flyer/utils';
import time from '@flyer/utils/time';
import { useAtom, useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { head } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { graphql } from '@/__generated__';
import { AppTypeMobile, LearnerProfileFragment, VersionNotification, } from '@/__generated__/graphql';
import HomePage from '@/components/exam/ExamHomeView';
import { JoinClassModalLazy, PlacementTestModalLazy, PlacementTestResultModalLazy, SummaryMonthModalLazy, SummaryYearModalLazy, WhatNewsModalLazy, } from '@/components/popups';
import { TestLevels, TestLevelsDocument, TestLevelsVariables, useGetLastPlacementTestLazyQuery, useGetScoreRankQueryLazyQuery, } from '@/graphql/generated/query';
import useBreakpointValue from '@/hooks/useBreakpointValue';
import useUserLocalTest from '@/hooks/useUserLocalTest';
import { initializeApollo } from '@/lib/apollo';
import ROUTES from '@/lib/routes';
import { useAuth } from '@/providers/auth';
import { lastPlacementTestIdAtom, profileViewRecapMonthlyAtom, profileViewWhatNewsAtom, scoreRankAtom, testLevelAtomResponse, timeUserStartVisitAtom, totalNotification, } from '@/store/home';
import { isHideSchool } from '@/lib/utils';
import TiktokPixel from 'tiktok-pixel';
async function getStaticProps() {
    const client = initializeApollo();
    const { data } = await client.query<TestLevels, TestLevelsVariables>({
        query: TestLevelsDocument
    });
    // const { data: checkPointPlacementTest } = await client.query<
    //   GetDataPlacementTest,
    //   GetDataPlacementTestVariables
    // >({
    //   query: GetDataPlacementTestDocument,
    //   variables: {
    //     where: {
    //       type: {
    //         _eq: 'PLACEMENT_TEST',
    //       },
    //     },
    //   },
    // });
    return { props: { data }, revalidate: 3600 * 24 };
}
const CountNoti = graphql(`
  query ViewerExamUnreadNotificationsCountQuery {
    viewerExamUnreadNotificationsCount
  }
`);
const ViewerGetVersionNotificationUpdate = graphql(`
  query ViewerGetVersionNotificationUpdate($appType: AppTypeMobile!) {
    versionNotification(appType: $appType) {
      appType
      contentHTML
      contentHTMLEn
      id
      image {
        id
        url
      }
      status
      version
      versionNotificationId
      videoId
    }
  }
`);
export default function Home({ data }: {
    data: TestLevels;
}) {
    useHydrateAtoms([
        [testLevelAtomResponse, data],
        // [checkPointPlacementTestAtom, head(checkPointPlacementTest?.testLevels)?.check_points],
    ]);
    const router = useRouter();
    const { currentLearnerProfile, user, learnerProfiles, loading } = useAuth();
    const setScoreRank = useSetAtom(scoreRankAtom);
    const setCount = useSetAtom(totalNotification);
    const setLastPlacementTestIdAtom = useSetAtom(lastPlacementTestIdAtom);
    const [latestPlacementTestResponse] = useGetLastPlacementTestLazyQuery({
        fetchPolicy: 'network-only'
    });
    const [getScorePlacementTest] = useGetScoreRankQueryLazyQuery({
        fetchPolicy: 'network-only'
    });
    const { data: dataWhatNews } = useQuery(ViewerGetVersionNotificationUpdate, {
        variables: {
            appType: AppTypeMobile.ExamWeb
        },
        context: {
            v2: true
        },
        fetchPolicy: 'cache-and-network'
    });
    const [timeUserStartVisit, setTimeUserStartVisit] = useAtom(timeUserStartVisitAtom);
    const [listProfileViewedRecap, setListProfileViewedRecap] = useAtom(profileViewRecapMonthlyAtom);
    const [userViewWhatsNews, setUserViewWhatsNews] = useAtom(profileViewWhatNewsAtom);
    const currentDay = time();
    const skipQueryNoti = useMemo(() => !currentLearnerProfile?.id, [currentLearnerProfile]);
    useQuery(CountNoti, {
        context: {
            v2: true
        },
        pollInterval: 20000,
        fetchPolicy: 'network-only',
        skip: skipQueryNoti,
        onCompleted: ({ viewerExamUnreadNotificationsCount }) => {
            const dataRes = viewerExamUnreadNotificationsCount;
            if (dataRes) {
                const count = dataRes || 0;
                setCount(count);
            }
        }
    });
    const breakpoint = useBreakpointValue();
    const isOnMobile = useMemo(() => {
        if (!breakpoint)
            return true;
        return breakpoint === 'sm';
    }, [breakpoint]);
    const [q, setQ] = useQueryParams({
        modalPlacementTest: BooleanParam,
        modalPlacementTestResult: BooleanParam,
        modalJoinClass: BooleanParam,
        checkpoint: StringParam,
        classcode: StringParam,
        placementTestId: StringParam,
        modalRecap: BooleanParam,
        monthlyRecap: StringParam,
        yearlyRecap: StringParam,
        modalWhatNews: BooleanParam
    });
    // useEffect(() => {
    //   //    const date = time().date();
    //   // fake date = 1 for test
    //   const timer = setTimeout(() => {
    //     const date = time().date();
    //     const previousMonth = time().subtract(1, 'month');
    //     if (!q.monthlyRecap && !q.yearlyRecap && date <= 7 && currentLearnerProfile?.id) {
    //       const checkExitShow = listProfileViewedRecap.filter(
    //         (i) =>
    //           i?.profileId === currentLearnerProfile?.id &&
    //           i?.date === previousMonth.format('YYYY-MM'),
    //       );
    //       if (
    //         checkExitShow.length === 0 &&
    //         previousMonth.format('MM') !== '12' &&
    //         previousMonth.format('MM') !== '01'
    //       ) {
    //         setQ({
    //           monthlyRecap: previousMonth.format('MM'),
    //           yearlyRecap: previousMonth.format('YYYY'),
    //         });
    //       }
    //     }
    //   }, 500);
    //   return () => clearTimeout(timer);
    // }, [
    //   currentLearnerProfile,
    //   currentLearnerProfile?.id,
    //   listProfileViewedRecap,
    //   q.monthlyRecap,
    //   q.yearlyRecap,
    //   setQ,
    //   isOnMobile,
    // ]);
    useEffect(() => {
        if (isOnMobile) {
            setQ({ monthlyRecap: undefined, yearlyRecap: undefined });
        }
    }, [isOnMobile, setQ]);
    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     const checkExitShow = listProfileViewedRecapYear.filter(
    //       (e) => e?.profileId === currentLearnerProfile?.learnerProfileId && e?.year === '2023',
    //     );
    //     const startDay = time('2023-12-24').startOf('day');
    //     const endDay = time('2024-01-07').endOf('day');
    //     const isBetWeen = currentDay.isBetween(startDay, endDay, null, '[]');
    //     if (
    //       currentLearnerProfile?.learnerProfileId &&
    //       checkExitShow.length === 0 &&
    //       isBetWeen &&
    //       !isOnMobile &&
    //       !loading &&
    //       !currentLearnerProfile?.isAnonymous &&
    //       ((learnerProfiles.length > 1 && time(timeUserStartVisit).diff(currentDay, 'hours') > 0) ||
    //         learnerProfiles.length === 1)
    //     ) {
    //       void router.push('/year-recap/2023');
    //     }
    //   }, 1000);
    //   return () => clearTimeout(timer);
    // }, [
    //   currentDay,
    //   currentLearnerProfile?.isAnonymous,
    //   currentLearnerProfile?.learnerProfileId,
    //   isOnMobile,
    //   learnerProfiles?.length,
    //   listProfileViewedRecapYear,
    //   loading,
    //   router,
    //   timeUserStartVisit,
    // ]);
    // logic what news
    // auto open
    useEffect(() => {
        if (dataWhatNews?.versionNotification?.version && userViewWhatsNews && user?.id) {
            const checkUserViewed = userViewWhatsNews?.find((check) => check.userId === user?.id &&
                check.appVersion === dataWhatNews?.versionNotification?.version);
            if (!checkUserViewed) {
                setQ({
                    modalWhatNews: true
                });
            }
        }
    }, [dataWhatNews?.versionNotification, setQ, user?.id, userViewWhatsNews]);
    const handlerCloseUserViewWhatNews = useCallback(() => {
        const checkUserViewed = userViewWhatsNews?.find((check) => check.userId === user?.id &&
            check.appVersion === dataWhatNews?.versionNotification?.version);
        if (!checkUserViewed && user?.id && dataWhatNews?.versionNotification?.version) {
            void setUserViewWhatsNews([
                ...userViewWhatsNews,
                {
                    userId: user?.id,
                    appVersion: dataWhatNews?.versionNotification?.version
                },
            ]);
        }
        setQ({ modalWhatNews: undefined });
    }, [
        dataWhatNews?.versionNotification?.version,
        setQ,
        setUserViewWhatsNews,
        user?.id,
        userViewWhatsNews,
    ]);
    useModal(SummaryMonthModalLazy, {
        open: Boolean(q.monthlyRecap) && Boolean(q.yearlyRecap) && !!currentLearnerProfile,
        year: q.yearlyRecap,
        month: q.monthlyRecap,
        onClose: () => {
            const checkExit = listProfileViewedRecap.filter((e) => e?.profileId === currentLearnerProfile?.id &&
                e?.date === `${q?.yearlyRecap as string}-${q?.monthlyRecap as string}`);
            if (currentLearnerProfile?.id && checkExit.length === 0 && q.yearlyRecap && q.monthlyRecap) {
                setListProfileViewedRecap((cur) => [
                    ...cur,
                    {
                        profileId: currentLearnerProfile.id,
                        date: `${q?.yearlyRecap as string}-${q?.monthlyRecap as string}`
                    },
                ]);
            }
            setQ({ monthlyRecap: undefined, yearlyRecap: undefined });
        }
    });
    useModal(PlacementTestModalLazy, {
        open: q.modalPlacementTest || false,
        onClose: () => setQ({ modalPlacementTest: undefined })
    });
    useModal(PlacementTestResultModalLazy, {
        open: q.modalPlacementTestResult || false,
        onClose: () => setQ({ modalPlacementTestResult: undefined, placementTestId: undefined })
    });
    useModal(WhatNewsModalLazy, {
        open: (q.modalWhatNews && !!dataWhatNews?.versionNotification) || false,
        onClose: handlerCloseUserViewWhatNews,
        data: dataWhatNews?.versionNotification as VersionNotification
    });
    useModal(JoinClassModalLazy, {
        open: (!loading && currentLearnerProfile && q.modalJoinClass) || false,
        onClose: () => setQ({ modalJoinClass: undefined, classcode: undefined }),
        onJoined: () => {
            if (q.checkpoint) {
                window.open(`/exam${ROUTES.REDIRECT_TO_CHECKPOINT(q.checkpoint)}`);
            }
            setQ({ modalJoinClass: undefined, classcode: undefined, checkpoint: undefined });
        }
    });
    const getData = useCallback(async (profile: LearnerProfileFragment | null | undefined) => {
        if (profile) {
            const lastPlacementTestResponse = await latestPlacementTestResponse({
                variables: {
                    where: {
                        learner_profile_id: {
                            _eq: profile?.id
                        },
                        type_attempt: {
                            _eq: 'placement_test'
                        },
                        submitted_at: {
                            _is_null: false
                        }
                    }
                },
                fetchPolicy: 'network-only'
            });
            const idLatestPlacementTest = head(lastPlacementTestResponse?.data?.user_attempts)?.id;
            if (!idLatestPlacementTest) {
                setScoreRank(undefined);
                return;
            }
            setLastPlacementTestIdAtom(idLatestPlacementTest);
            if (idLatestPlacementTest) {
                void getScorePlacementTest({
                    variables: {
                        id: idLatestPlacementTest
                    },
                    onCompleted: (res) => {
                        setScoreRank(res);
                    }
                });
            }
        }
    }, [getScorePlacementTest, latestPlacementTestResponse, setLastPlacementTestIdAtom, setScoreRank]);
    const handleJoinClass = useCallback(() => {
        if (!loading && !currentLearnerProfile && q.modalJoinClass) {
            void router.push(ROUTES.SIGN_IN(router.asPath));
        }
    }, [currentLearnerProfile, loading, q.modalJoinClass, router]);
    const isNeedSwitchProfile = useMemo(() => {
        return (!!user &&
            !!timeUserStartVisit &&
            time(timeUserStartVisit).diff(currentDay, 'hours') <= 0 &&
            !!currentLearnerProfile &&
            learnerProfiles.length > 1 &&
            (!!user.email || !!user?.phoneNumber));
    }, [currentDay, currentLearnerProfile, learnerProfiles.length, timeUserStartVisit, user]);
    // force user update school or choose profile
    const handleSwitchOrUpdateProfile = useCallback(() => {
        if (isNeedSwitchProfile) {
            void router.push({ pathname: '/profiles', query: { ...router.query } });
        }
        if (!isNeedSwitchProfile &&
            !!currentLearnerProfile &&
            !currentLearnerProfile.school &&
            !isHideSchool()) {
            void router.push({ pathname: '/update-profile', query: { ...router.query } });
        }
    }, [currentLearnerProfile, isNeedSwitchProfile, router]);
    const handleNoProfile = useCallback(() => {
        if (user && !currentLearnerProfile && (user.email || user?.phoneNumber)) {
            void router.push('/create-profile');
        }
    }, [currentLearnerProfile, router, user]);
    useModal(SummaryYearModalLazy, {
        open: q.modalRecap || false,
        onClose: () => {
            // modal year 2022
            setQ({ modalRecap: undefined });
        }
    });
    /* from 2023 -> convert to page for tracking and share
     - handler logic 2023
    */
    useEffect(() => {
        void getData(currentLearnerProfile);
    }, [getData, currentLearnerProfile]);
    const userLocalTest = useUserLocalTest();
    const openedPlacementTest = useRef(false);
    useEffect(() => {
        if (userLocalTest) {
            if (q.modalPlacementTest) {
                openedPlacementTest.current = true;
                return;
            }
            if (!openedPlacementTest.current) {
                openedPlacementTest.current = true;
                setQ({ modalPlacementTest: true });
                return;
            }
            return;
        }
        handleSwitchOrUpdateProfile();
    }, [handleSwitchOrUpdateProfile, q.modalPlacementTest, router, setQ, userLocalTest]);
    useEffect(() => {
        handleNoProfile();
    }, [handleNoProfile]);
    useEffect(() => {
        handleJoinClass();
    }, [handleJoinClass]);
    useEffect(() => {
        if (learnerProfiles.length > 1 && !timeUserStartVisit && currentLearnerProfile) {
            setTimeUserStartVisit(currentDay.add(24, 'hours').format());
        }
    }, [
        currentDay,
        currentLearnerProfile,
        setTimeUserStartVisit,
        timeUserStartVisit,
        learnerProfiles,
    ]);
    // hide scrollbar on mobile
    useEffect(() => {
        const styleEl = document.createElement('style');
        if (isOnMobile) {
            document.head.append(styleEl);
            styleEl.sheet?.insertRule('body { overflow: hidden; }');
        }
        return () => {
            styleEl.remove();
        };
    }, [isOnMobile]);
    // tracking facebook
    useEffect(() => {
        if (isClient() && !loading) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.fbq('track', 'ExamHomePageView', {
                id: user?.id,
                phone: user?.phoneNumber,
                email: user?.email,
                displayName: currentLearnerProfile?.displayName
            });
            void TiktokPixel.track('ExamHomePageView', {
                id: user?.id,
                phone: user?.phoneNumber,
                email: user?.email,
                displayName: currentLearnerProfile?.displayName
            });
        }
    }, [loading, currentLearnerProfile?.displayName, user?.email, user?.id, user?.phoneNumber]);
    return <HomePage />;
}

    async function __Next_Translate__getStaticProps__19178866ddb__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/home/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19178866ddb__ as getStaticProps }
  